@import "/src/assets/scss/abstract/abstract.scss";

$gray: map-get($parent-colors , 'cool-gray');
$cool-primary: map-get($parent-colors , 'cool-primary');
$cool-gray: map-get($parent-colors , 'cool-gray');

.create-account-stepper-wrapper {
    margin: 20px 0 90px 0;
    @include parent-breakpoint('lt-lg') {
        margin: 20px 0 40px 0;
    }
    .stepper-cointainer {
        width: 680px;
        margin: auto;
        position: relative;
        display: flex;
        justify-content: space-between;
        &:before {
            content: "";
            position: absolute;
            width: calc(100% - 2px);
            height: 3px;
            background-color: map-get($gray , 700);
            top: 12px;
        }
        .widget-box {
            .widget {
                position: relative;
                @include circle(28px);
                @include border-radius(100px);
                border: 2px solid map-get($gray , 700);
                overflow: hidden;
                background-color: white;
                .inner {
                    position: absolute;
                    @include circle(16px);
                    @include border-radius(100px);
                    border: 1px solid map-get($gray , 700);
                    background-color: map-get($cool-primary , 300);
                    opacity: 0;
                }
            }
            p {
                width: 140px;
                color: map-get($gray , 700);
                transform: translateX(-54px);
                text-align: center;
                margin: 4px 0 0 0 !important;
            }
            &.visible {
                .widget .inner {
                    opacity: 1;
                }
            }
        }
    }
}