@import "/src/assets/scss/abstract/abstract.scss";



form {
    .form-check {
        margin-bottom: 0;
    }
    .form-control, label {
       @extend .ft-head;
    }
    .input-box {
        margin-bottom: 50px;
        @include parent-breakpoint('lt-md') {
            margin-bottom: 24px;
        }
        label {
            @extend .ls--4;
            margin-bottom: 8px;
            @extend .text-gray-900;
        }
        .form-control {
            @extend .bg-gray-100;
            border: 1px solid map-get($cool-gray , 200);
            color: map-get($cool-gray , 900);
            @include border-radius(6px);
            height: 48px;
            @extend .text-normal;
            @include placeholder {
                @extend .text-gray-500;
            }
            &:-webkit-autofill, 
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus, 
            &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0px 1000px map-get($cool-gray, 100) inset;
            }
            &.simple {
                background-color: white !important;
                border: 1px solid map-get($cool-gray , 500);
                & + .input-group-text {
                    background-color: white;
                    border: 1px solid map-get($cool-gray , 500);
                }
                &:-webkit-autofill, 
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0px 1000px white inset;
                }
            }
        }
        .input-group {
            .input-group-text {
                @extend .bg-gray-100;
                border: none;
            }
        }
        &.auth-box {
            margin-bottom: 17px;
            label {
                @extend .text-gray-980;
            }
            .form-control {
                border: none;
                @extend .bg-primary-100;
                @include border-radius(8px);
                height: 56px;
                @include placeholder {
                    @extend .text-gray-300;
                }
                &:-webkit-autofill, 
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0px 1000px map-get($cool-primary, 100) inset;
                }
            }
            .input-group {
                .input-group-text {
                    @extend .bg-primary-100;
                }
            }
        }
        &.readonly-box {
            label {
                @extend .text-gray-980;
            }
            .form-control {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
    .form-check-input {
        border: 1px solid;
        @extend .border-primary-300;
        &[type="checkbox"] {
            &:checked {
                @extend .bg-primary-300;
            }
        }
        &[type="radio"] {
            &:checked + label,
            &:not(:checked) + label {
                position: relative;
                cursor: pointer;
            }
            &:checked + label:before,
            &:not(:checked) + label:before {
                content: '';
                position: absolute;
                left: -25px;
                top: 2px;
                width: 18px;
                height: 18px;
                border: 1px solid;
                @extend .border-primary-950;
                border-radius: 100%;
                background: white;
            }
            &:checked + label:after,
            &:not(:checked) + label:after {
                content: '';
                width: 10px;
                height: 10px;   
                @extend .bg-primary-950;
                position: absolute;
                left: -21px;
                top: 6px;
                @include border-radius(100%);
            }
            &:not(:checked) + label:after {
                opacity: 0;
                transform: scale(0);
            }
            &:checked + label:after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    textarea {
        &.form-control {
            min-height: 200px;
            resize: none;
        }
    }
}

//buttons

.btn {
    min-width: 100px;
    height: 48px;
    line-height: 48px;
    color: white;
    @include border-radius(6px);
    @extend .ft-default;
    @extend .fw-medium;
    @extend .text-normal;
    border: none;
    padding: 0 22px;
    &:hover, &.active, &:active, &:focus {
        color: white;
    }
    &.btn-big {
        height: 54px;
        line-height: 54px;
        @extend .text-base;
        &:hover, &.active {
            opacity: 1;
            @extend .bg-primary-950;
        }
    }
    &.btn-special {
        @extend .bg-primary-300;
        @extend .ft-head;
        @extend .text-base;
        &:hover, &.active {
            @extend .bg-primary-950;
        }
    }
    &.btn-gray {
        @extend .bg-primary-100;
        @extend .text-gray-970;
        @extend .text-base;
        &:hover, &.active {
            @extend .bg-primary-950;
            color: white
        }
    }
    &.submit-btn {
        @extend .text-base;
        @include parent-breakpoint('gt-xs') {
            min-width: 180px;
        }
    }
    &.link-dark-btn {
        @extend .text-gray-980;
        &:hover {
            color: inherit
        }
    }
    &.disabled {
        background-color: map-get($cool-gray , 350) !important;
        color: rgba(white , 0.4);
    }
}

//Select Group Box
.select-group-box {
    .input-box {
        position: relative;
        input {
            cursor: pointer;
            &:focus {
                box-shadow: none;
            }
        }
        .select-input-dropdown {
            position: absolute;
            width: 100%;
            margin: 4px 0 0 0 !important;
            z-index: 1000;
            box-shadow: 0 10px 25px rgba(map-get($cool-gray , 900), 0.1);
            li {
                padding-top: 10px;
                padding-bottom: 10px;
                @extend .text-gray-900;
                @extend .ft-head;
                @extend .text-normal;
                cursor: pointer;
                border: 1px solid map-get($cool-gray , 200);
                &:hover {
                    @extend .bg-gray-50;
                }
            }
        }
    }
}

//Select Box
.select-box {
    .input-box {
        position: relative;
        .form-control {
            border-radius: 0;
            @extend .text-gray-700;
            &:focus {
                box-shadow: none
            }
            @include placeholder {
                color: $primary;
            }
        }
        .select-input-dropdown {
            position: absolute;
            width: 100%;
            z-index: 1000;
            border-radius: 0;
            li {
                padding-top: 10px;
                padding-bottom: 10px;
                @extend .text-gray-700;
                @extend .ft-head;
                @extend .text-normal;
                cursor: pointer;
                border: 1px solid map-get($cool-gray , 200);
                &:hover {
                    @extend .bg-gray-50;
                }
            }
        }
    }
}


//Select Box
.select-overlap-box {
    .input-box {
        position: relative;
        .form-control {
            width: 170px;
            border-radius: 10px;
            height: 56px;
            @extend .text-gray-900;
            @extend .ft-default;
            font-size: $font-h6;
            padding: 0 0 0 16px;
            &:focus {
                box-shadow: none
            }
            @include placeholder {
                @extend .text-gray-900;
            }
            &.trans {
                color: transparent;
            }
        }
        .input-group-text {
            border: 0;
            padding: 0 16px 0 4px;
            border-radius: 10px;
        }
        .select-input-dropdown {
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 1000;
            border-radius: 10px;
            box-shadow: 0px 3px 6px rgba(map-get($cool-gray, 900), 0.15);
            li {
                padding-top: 12px;
                padding-bottom: 12px;
                @extend .text-gray-900;
                @extend .ft-default;
                @extend .text-normal;
                @extend .fw-medium;
                cursor: pointer;
                border: 0;
                border-bottom: 1px solid map-get($cool-gray , 200);
                &:hover {
                    @extend .bg-gray-50;
                }
                &.active {
                    @extend .bg-gray-200;
                    color: inherit;
                }
            }
        }
    }
    &.primary {
        .input-box {
            .form-control, .input-group-text {
                background-color: map-get($cool-primary, 100) !important;
            }
            .select-input-dropdown {
                border: 1px solid map-get($cool-primary , 300);
                box-shadow: none;
                li {
                    &, &:hover {
                        background-color: map-get($cool-primary, 100);
                    }
                    
                }
                &.image-dropdown {
                    background-color: white;
                    .image-item {
                        background-color: transparent;
                        width: 80px;
                        height: 80px;
                        margin: 10px 0;
                        display: inline-block;
                        border: 0;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            .image-input {
                position: absolute;
                background-color: map-get($cool-primary, 100) !important;
                width: calc(100% - 60px);
                height: 56px;
                .image-select {
                    width: 32px;
                    margin: 10px 0 0 14px;
                }
            }
            
        }
    }
}