
// DONT MODIFY
@import "/src/assets/scss/abstract/abstract.scss";

  .shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }
  
  .rounded {
    @include border-radius(6px);
    //overflow: hidden;
  }

  .rounded-md {
    @include border-radius(8px);
    //overflow: hidden;
  }

  .rounded-lg {
    @include border-radius(10px);
    //overflow: hidden;
  }

  .rounded-xl {
    @include border-radius(15px);
    //overflow: hidden;
  }

  .op-50 {
    opacity: 0.5;
  }
  
  //font size
  .text-xs {
    font-size: $font-tiny;
  }

  .text-smr {
    font-size: $font-smaller;
  }
  
  .text-sm {
    font-size: $font-small;
  }

  .text-base {
    font-size: $font-base;
  }

  .text-normal {
    font-size: $font-normal;
  }
  

  h6, .text-h6 {
    font-size: $font-h6;
  }

  h5, .text-h5 {
    font-size: $font-h5;
  }

  h4, .text-h4 {
    font-size: $font-h4;
  }

  h3, .text-h3 {
    font-size: $font-h3;
  }

  h2, .text-h2 {
    font-size: $font-h2;
  }

  h1, .text-h1 {
    font-size: $font-h1;
  }

  .text-huge {
    font-size: $font-huge;
  }

  .text-big {
    font-size: $font-big;
  }

  .ft-head {
    font-family: 'Poppins', sans-serif;
  }

  .ft-default {
    font-family: 'Product Sans',  sans-serif;
  }
  
  //cool gray
  $cool-gray: map-get($parent-colors, cool-gray);
  
  @each $key, $value in $cool-gray {
  
    .text-gray-#{$key} {
        color: #{$value};
    }

    .bg-gray-#{$key} {
      background-color: #{$value};
    }

    .border-gray-#{$key} {
      border: 1px solid #{$value};
    }

  }

  //cool cyan
  $cool-primary: map-get($parent-colors, cool-primary);
  
  @each $key, $value in $cool-primary {
  
    .text-primary-#{$key} {
        &, &:hover {
          color: #{$value};
        }
    }

    .bg-primary-#{$key} {
      background-color: #{$value};
    }

    .border-primary-#{$key} {
      border: 1px solid #{$value} !important;
    }

  }

  //yellow
  $green: map-get($parent-colors, cool-green);

  @each $key, $value in $green {
  
    .text-green-#{$key} {
      &, &:hover {
        color: #{$value};
      }
    }

    .bg-green-#{$key} {
      background-color: #{$value};
    }

  }

  //blue
  $blue: map-get($parent-colors, cool-blue);

  @each $key, $value in $blue {
  
    .text-blue-#{$key} {
      &, &:hover {
        color: #{$value};
      }
    }

    .bg-blue-#{$key} {
      background-color: #{$value};
    }

  }

  //red
  $red: map-get($parent-colors, cool-red);

  @each $key, $value in $red {
  
    .text-red-#{$key} {
      &, &:hover {
        color: #{$value};
      }
    }

    .bg-red-#{$key} {
      background-color: #{$value};
    }

    .border-red-#{$key} {
      border: 1px solid #{$value} !important;
    }

  }

   //orange
   $orange: map-get($parent-colors, cool-orange);

   @each $key, $value in $orange {
  
    .text-orange-#{$key} {
      &, &:hover {
        color: #{$value};
      }
    }

    .bg-orange-#{$key} {
      background-color: #{$value};
    }

  }

  //yellow
  $yellow: map-get($parent-colors, cool-yellow);

  @each $key, $value in $yellow {
  
    .text-yellow-#{$key} {
      &, &:hover {
        color: #{$value};
      }
    }

    .bg-yellow-#{$key} {
      background-color: #{$value};
    }

  }

  //status color
  $status: map-get($parent-colors, status-color);

  @each $key, $value in $status {
  
    .text-status-#{$key} {
        color: #{$value} !important;
    }

  }
   

  $font-weights: (
    'fw-light': 300,
    'fw-regular': 400,
    'fw-medium': 500,
    'fw-semibold': 600,
    'fw-bold': 700
  );

  @each $key, $value in $font-weights {
  
    .#{$key} {
        font-weight: #{$value};
    }
  }

  $spaces: (
    'ls--2': 0.2px,
    'ls--25': 0.25px,
    'ls--4': 0.4px,
    'ls--15': 1.5px
  );

  @each $key, $value in $spaces {
  
    .#{$key} {
        letter-spacing: #{$value};
    }
  }

  $line-heights: (
    'lh-14': 14px
  );

  @each $key, $value in $line-heights {
  
    .#{$key} {
        line-height: #{$value};
    }
  }
