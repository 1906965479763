@import "/src/assets/scss/abstract/abstract.scss";

.profile-settings-wrapper {
    .profile-settings-box {
        .nav-tabs {
            display: flex;
            @include parent-breakpoint('gt-md') {
                justify-content: right;
            }
            border: none;
            .nav-item {
                .nav-link {
                    border: none;
                    padding-right: 10px;
                    padding-left: 10px;
                    .btn {
                        color: map-get($cool-gray, 900);
                        @extend .ft-head;
                        font-weight: 400 !important;
                        font-size: $font-h6;
                    }
                    @include parent-breakpoint('lt-lg') {
                        padding-right: 12px;
                        padding-left: 0;
                    }
                }
            }
        }
        .tab-content {
            margin: 20px 0 0 0;
            .card {
                .card-header, .card-body {
                    padding: 24px 36px;
                }
                .desp-box {
                    @extend .ft-default;
                    li { width: 250px; }
                    .renewal-box {
                        .info-icon {
                            width: 22px;
                            cursor: pointer;
                            margin: -4px 0 0 0;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}


.billing-settings-wrapper {
    .billing-info {
        .add {
            @include circle(22px);
            border: 2px solid map-get($cool-primary , 950);
        }
    }
}

.purchase-history-wrapper {
    .invoice-card {
        .img-box {
            width: 160px;
            height: auto;
            border-radius: 6px;
            margin-bottom: 20px;
            overflow: hidden;
            &.icon-box {
                width: 60px;
                height: 60px;
                border-radius: 60px;
                border: 3px solid map-get($cool-primary , 800);
                overflow: hidden;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .amount-list {
            .list-item {
                border-top: 1px solid map-get($cool-gray, 180);
                &:last-child {
                    border-top: 1px solid map-get($cool-primary, 800);
                }
            }
        }
    }
    .contact-card {
        textarea {
            width: 100%;
            height: 300px;
            background-color: map-get($cool-primary , 100);
            border-radius: 6px;
            border: none;
            resize: none;
            padding: 8px;
        }
        .submit-btn {
            margin-top: 30px;
        }
    }
}