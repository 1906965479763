@import "/src/assets/scss/abstract/abstract.scss";


$cool-primary: map-get($parent-colors, 'cool-primary');
$yellow: map-get($parent-colors, 'cool-yellow');

.help-wrapper {
    .help-container {
        .img-block {
            img {
                width: 300px;
            }
        }
        .questions-block {
            margin: 30px 0 0 0;
            .questions-container {
                .card {
                    box-shadow: 0px 3px 4px rgba(#212121, 0.1);
                    @include border-radius(6px);
                    overflow: hidden;
                    .card-header, .card-body {
                        background: white;
                        border: none;
                    }
                    .card-header {
                        padding: 24px;
                        .title {
                            padding-right: 20px !important;
                        }
                        .state-icons {
                            .icon-circle {
                                @include circle(18px)
                            }
                        }
                    }
                    .card-body {
                        padding: 0 24px 24px 24px;
                        h6 {
                            line-height: 28px;
                        }
                    }
                }
            }
        }
        .help-form-box {
            .help-link {
                margin: 48px 0 36px 0 !important;
                a { color: map-get($cool-primary, 500); }
            }
            textarea {
                &.form-control {
                    @include border-radius(6px);
                    background-color: white;
                    padding: 20px;
                    box-shadow: 0px 3px 4px rgba(#212121, 0.1);
                }
            }
            .submit-btn {
                margin: 30px 0 0 0;
                min-width: 200px;
            }
        }
    }
}