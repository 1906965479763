@import "/src/assets/scss/abstract/abstract.scss";


$theme-colors : (
    'primary': $primary,
    'secondary': $secondary,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger
);

$custom-colors: (
    "custom-red": $custom-red
);

$theme-colors: map-merge($custom-colors, $theme-colors);

.btn.btn-primary {
    @include custom-button-variant(white, map-get($cool-primary, 500), map-get($cool-primary, 950));
}

.btn.btn-outline-primary {
    @include custom-outline-button-variant(map-get($cool-gray, 950), white,  map-get($cool-primary, 900));
}


.btn.btn-secondary {
    @include custom-button-variant(white, map-get($cool-gray, 500), map-get($cool-gray, 500));
}

