@import "/src/assets/scss/abstract/abstract.scss";


$cool-primary: map-get($parent-colors, 'cool-primary');
$cool-gray: map-get($parent-colors, 'cool-gray');
$cool-blue: map-get($parent-colors, 'cool-blue');

$yellow: map-get($parent-colors, 'cool-yellow');

//signin and resetpassword
.signin-wrapper, .reset-password-wrapper, .signup-wrapper {
    position: relative;
    .logo-container {
        position: absolute;
        top: 61px;
        left: 140px;
        z-index: 100;
        img {
            width: 138px;
            height: auto;
        }
    }
    .content-container {
        margin: 112px 0 0 0;
        @include parent-breakpoint('lt-lg') {
            margin: 0;
        }
        .left {
            position: relative;
            flex-basis: 45%;
            left: -32px;
            .block-img {
                position: absolute;
            }
        }
        .right {
            margin: 60px 0 0 0;
            flex: 1;
            .form-container {
                margin: 40px 0;
                .instruct {
                    font-weight: 400;
                }
                .success {
                    img {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
            @include parent-breakpoint('xs') {
                .form-container {
                    margin: 12px 12px 0 12px;
                }
                .logo-container {
                    margin: 0 16px;
                }
            }
        }
    }
}

//profile menu
.profile-menu-wrapper {
    margin: 16px 0 0 20px;
    width: 260px;
    @include parent-breakpoint('lt-lg') {
        margin: 4px 0 0 20px;
    }
    &.show {
        background: white;
        @include border-radius(10px);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
    }
    .profile-menu-top {
        padding: 14px 16px 19px 16px;
        @include parent-breakpoint('lt-lg') {
            padding: 14px 16px 12px 16px
        }
        .img-box {
            width: 60px;
            height: 60px;
            border-radius: 60px;
            border: 3px solid white;
            overflow: hidden;
            @include parent-breakpoint('lt-lg') {
                width: 50px;
                height: 50px;
                border-radius: 50px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .user-icon {
                width: 100%;
                height: 100%;
            }
        }
        .name-box {
            margin: 6px 0 0 14px;
            .name {
                width: 130px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .role {
                color: rgba(map-get($gray, 980), 0.5);
            }
        }
        .arrow {
            margin: 5px 4px 0 10px;
        }
    }
    .profile-menu-bottom {
        background: white;
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 1000;
        box-shadow: 0 1rem 3rem 0 rgba(map-get($cool-gray, 960), 0.15);
        @include border-radius(10px);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        @include parent-breakpoint('lt-lg') {
            margin: 0;
        }
        .list {
            li {
                cursor: pointer;
                display: flex;
                border-top: 1px solid map-get($gray, 200);
                a {
                    color: map-get($gray, 980);
                    padding: 14px 24px;
                    width: 100%;
                    display: inline-block;
                }
            }
        }
    }
}

//Notification
.header-notification-wrapper {
    position: relative;
    margin: 44px 0 0 30px;
    @include parent-breakpoint('lt-lg') {
        margin: 30px 0 0 30px;
    }
    .notify-box {
        position: relative;
        cursor: pointer;
        &.dropdown-toggle {
            &:after {
                border: none;
            }
        }
        img {
            width: 20px;
        }
        .badge {
            position: absolute;
            @include circle(24px);
            border: 1px solid map-get($yellow , 400);
            background: white;
            top: -10px;
            right: -10px;
        }
    }
    .notify-dropdown {
        position: absolute;
        top: 42px;
        background-color: white;
        @include border-radius(6px);
        right: -9px;
        border: 1px solid map-get($cool-blue, 200);
        .arrow {
            position: absolute;
            top: -8px;
            right: 10px;
            border-bottom: 8px solid white;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
        }
        .list-group-item {
            @extend .ft-default;
            border: none;
            padding: 16px;
            min-width: 350px;
            cursor: pointer;
            &:hover {
                background-color: map-get($cool-blue, 100);
            }
            .name {
                @include circle(46px);
                overflow: hidden;
                color: white;
            }
        }
    }
}

//contact
.contact-wrapper {
    .contact-container {
        position: relative;
        @include parent-breakpoint('gt-md') {
            padding: 43px 34px;
            background-color: white;
        }
        .contact-card {
            width: 80%;
            margin: auto;
            padding: 0 40px;
            @include parent-breakpoint('lt-md') {
                width: 100%;
            }
            .liner {
                position: relative;
                &:after {
                    position: absolute;
                    content: "";
                    width: 23px;
                    height: 2px;
                    bottom: -5px;
                    left: 2px;
                    @extend .bg-primary-950;
                }
            }
            .form-box {
                margin: 50px 0;
            }
        }
    }
}

//dashboard mystudent
.dashboard-my-student-wrapper {
    position: relative;
    margin: 36px 0 0 0;
    .content {
        .details {
            position: relative;
            padding: 24px 20px;
            .img-wrapper {
                @include circle(60px);
                overflow: hidden;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .plan {
                position: absolute;
                bottom: 28px;
            }
        }
        @include parent-breakpoint('lt-md') {
            margin: 0 0 24px 0;
            height: auto !important;
        }
    }
}

//dashboard assigned wrapper
.dashboard-assigned-lesson-wrapper {
    .card {
        box-shadow: 1px 1px 18px 0 rgba(map-get($cool-gray, 870), 0.1);
    }
    .lesson-box {
        margin: 0 0 12px 0;
        .progress {
            position: relative;
            width: 100%;
            @include border-radius(4px);
            height: 18px;
            .status-bar {
                position: absolute;
                @include border-radius(4px);
            }
        }
    }
}

//signout confirm
.signout-confirm-wrapper {
    padding: 40px 0;
    max-width: 100%;
    @include parent-breakpoint('gt-md') {
        max-width: 400px;
    }
    .button-box {
        .btn {
            min-width: 180px;
            @include parent-breakpoint('gt-md') {
               &:not(:last-child) {
                margin-right: 20px;
               }
            }
        }
    }
}

//billing info
.billing-info-wrapper, .payment-info-wrapper {
    .summary-card {
        @include parent-breakpoint('lt-md') {
            margin-top: 30px;
        }
        .payment-summary-wrapper {
            form {
                .coupon-input {
                    border-radius: 6px;
                    height: 50px;
                    background-color: white;
                }
                .coupon-btn {
                    margin-top: 32px;
                    width: 100%;
                    height: 50px;
                    border: 1px solid rgba(map-get($cool-primary , 800), 0.3);
                    background-color: white;
                    line-height: 46px;
                    border-radius: 0;
                    @extend .fw-regular;
                    &:hover, &:active, &:focus {
                        color: inherit;
                    }
                }
            }
        }
    }
}


.add-payment-card-wrapper {
    label, .form-control {
        @extend .ft-default;
        @extend .text-base;
        color: map-get($cool-gray , 950);
    }
    .payment-cards-container {
        .payment-box {
            cursor: pointer;
            position: relative;
            .card { width: 110px; }
            .checked { 
                position: absolute;
                top: 6px;
                right: 6px;
                img { width: 30px; }
                }
        }
    }
    .expiry-date-box {
        .react-datepicker-wrapper {
            width: auto !important;
        }
    }
    .save-card {
        .check-box {
            &, input {
                width: 36px;
                height: 36px;
            }
            input {
                position: absolute;
                z-index: 101;
                opacity: 0;
                cursor: pointer;
                & + .input-check {
                    z-index: 100;
                    position: absolute;
                    &:before, &:after {
                        content: "";
                        position: absolute;
                    }
                    &:before {
                        width: 36px;
                        height: 36px;
                        background-color: map-get($cool-primary , 800);
                        border-radius: 6px;
                        border: 2px solid transparent
                    }
                    &:after {
                        width: 12px;
                        height: 20px;
                        top: 12px;
                        left: 5px;
                        transform: rotate(45deg) translateY(-50%);
                        border-bottom: 3px solid transparent;
                        border-right: 3px solid transparent;
                    }
                }
                &:checked {
                    & + .input-check {
                        &:after {
                            border-bottom-color: map-get($cool-gray , 950);
                            border-right-color: map-get($cool-gray , 950);
                        }
                    }
                }
            }
        }
    }
}

.swap-account-modal {
    transform: translateY(40%) !important;
    .modal-content {
        overflow: hidden;
        .swap-account-wrapper {
            .content {
                .desp {
                    width: 70%;
                    margin: auto;
                    padding: 60px 30px 0 30px;
                    @include parent-breakpoint('lt-md') {
                        width: 100%;
                    }
                    .student-list {
                        margin: 50px 0;
                        .form-check {
                            &:not(:last-child) {
                                margin-bottom: 32px;
                            }
                            .form-check-input {
                                display: none;
                                &[type="radio"] {
                                    &:checked + label:before,
                                    &:not(:checked) + label:before {
                                        width: 22px;
                                        height: 22px;
                                        top: 6px;
                                        background: transparent;
                                    }
                                    &:checked + label:after,
                                    &:not(:checked) + label:after {
                                        width: 14px;
                                        height: 14px;
                                        top: 10px;
                                    }
                                }
                            }
                            .form-check-label {
                                font-size: 24px;
                                @extend .ft-head;
                                padding-left: 16px;
                            }
                        }
                        
                    }
                    .goto-btn {
                        height: 70px;
                        line-height: 70px;
                        font-size: 24px;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

//Payment Card
.payment-card {
    margin-top: 20px;
    margin: 20px 0 36px 0;
    .title-box {
       padding: 8px 12px;
    }
    .content {
        padding: 10px 12px;
        .check-item {
            @include circle(20px);
            position: relative;
            border: 2px solid map-get($cool-gray, 700);
            cursor: pointer;
            .inner {
                position: absolute;
                @include circle(10px);
                background-color:  map-get($cool-gray, 700);
            }
            &.default {
                border: 2px solid map-get($cool-primary, 500);
                .inner {
                    background-color:  map-get($cool-primary, 500);
                }
            }
        }
        .card-img {
            width: 80px;
        }
        .form-control {
            border: 1px solid rgba(map-get($cool-gray , 700), 0.36);
            border-radius: 0;
        }
    }
}

//welcome user
.setup-confirmation-wrapper {
    .welcome-box {
        padding: 40px;
        height: 675px;
        @include parent-breakpoint('lt-lg') {
            height: auto;
        }
        .message {
            width: 440px;
            .title {
                padding: 38px 0 8px 0 !important;
                margin-bottom: 0!important;
            }
        }
    }
}

//terms
.terms-wrapper {
    .list {
        margin: 0 20px !important;
        li {
            list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='-1 -1 2 2'><circle r='1' /></svg>");
            text-align: left;
            font-size: 16px;
            padding: 0 0 0 10px;
            &:not(:last-child) {
                margin-bottom: 50px;
            }
        }
    }
}

//my profile
.my-profile-wrapper {
    .edit-btn {
        color: map-get($cool-primary , 920);
        @extend .text-base;
        &:hover {
            color: map-get($cool-primary , 920);   
        }
    }

}

//update password modal
.update-password-modal-wrapper {
    form {
        .title {
            padding-bottom: 35px !important;
        }
        .input-box, .progress {
            margin-bottom: 34px
        }
        .progress {
            border-radius: 5px;
            height: 5px;
            .progress-bar {
                background-color: map-get($cool-primary , 800);
            }
        }
    }
}

//student profile
.student-profile-wrapper {
    .student-btn {
        background-color: map-get($cool-primary , 920);
        color: white;
        border-radius: 10px;
    }
    .student-card-box {
        border-radius: 4px;
        overflow: hidden;
        .column {
            padding: 38px;
            @include parent-breakpoint('lt-lg') {
                padding: 0 38px 38px 38px ;
                &.first-column {
                    padding: 38px 38px 0 38px;
                }
            }
            &.space-top {
                @include parent-breakpoint('gt-md') {
                    padding-top: 80px;
                }
            }
        }
        .liner {
            border-right: 1px solid rgba(map-get($cool-gray , 960), 0.1);
        }
        .package-box {
            .input-group-text {
                border: 0 !important;
                padding: 0;
                .upgrade-btn {
                    height: 100%;
                    background-color: map-get($cool-primary, 920);
                    position: relative;
                    left: -2px;
                }
            }
        }
    }
    .no-students-container {
        .student-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            .content {
                text-align: center;
            }
        }
    }
}

//student account delete
.delete-student-account {
    padding: 80px !important;
    @include parent-breakpoint('lt-lg') {
        padding: 38px;
    }
}

.delete-assignment-wrapper {
    .button-box {
        .btn {
            min-width: 180px;
            @include parent-breakpoint('gt-md') {
               &:not(:last-child) {
                margin-right: 20px;
               }
            }
        }
    }
}

//modal fullscreen
.modal {
    .fullscreen {
        position: fixed;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        background-color: map-get($cool-primary, 100);
        max-width: inherit;
        margin: 0;
        .modal-content {
            border: 0;
            background: transparent;
        }
    }
}

//preview and review
.preview-review-wrapper {
    .top {
        padding: 12px 0;
        font-size: 24px;
        .assign {
            cursor: pointer;
            &:hover {
                color: map-get($cool-primary, 950);
            }
        }
    }
    .paper-container {
        margin: 36px 0;
        .content {
            width: 85%;
            margin: 0 auto;
            .stepper-wrapper {
                position: relative;
                .stepper-box {
                    text-align: center;
                    .circle {
                        @include circle(34px);
                        @include border-radius(40px);
                        border: 2px solid map-get($cool-primary, 300);
                        color: map-get($cool-primary, 300);
                        &:not(:last-child) {
                            margin-right: 16px;
                        }
                        &.correct {
                            border-color: map-get($cool-green , 400);
                            background-color: map-get($cool-green , 400);
                            color: white;
                        }
                        &.wrong {
                            border-color: map-get($cool-red , 600);
                            background-color: map-get($cool-red , 600);
                            color: white;
                        }
                    }
                }
                .close-btn {
                    position: absolute;
                    @include border-radius(30px); 
                    color: white;
                    text-align: center;
                    background-color: map-get($cool-primary, 950);
                    padding: 6px 0;
                    width: 120px;
                    top: -1px;
                    right: 0;
                }
            }
            .ques-wrapper {
                margin: 46px 0 30px 0;
                background-color: white;
                @include border-radius(16px);
                padding: 40px 0;
                .inner {
                    width: 70%;
                    margin: 0 auto;
                    .ques-area {
                        .text {
                            display: flex;
                            line-height: 32px;
                        }
                        .img-box {
                            margin: 40px 0;
                        }
                        .ans-box {
                            .ans {
                                margin: 0 8px 24px 0;
                                box-shadow: 0px 6px 12px 4px map-get($cool-blue , 50);
                                background: white;
                                padding: 8px 14px;
                                @include border-radius(20px);
                                color: map-get($cool-gray , 700);
                                cursor: pointer;
                                border: 2px solid transparent;
                                li {
                                    list-style-type: upper-alpha;
                                    position: relative;
                                    left: 24px;
                                }
                                &.correct {
                                    border-color: map-get($cool-green , 400)
                                }
                                &.wrong {
                                    border-color: map-get($cool-red , 600)
                                }
                            }
                        }
                        .nav {
                            display: flex;
                            justify-content: center;
                            margin: 50px 0 0 0;
                            .btn {
                                color: white;
                                background-color: map-get($cool-primary , 950);
                                @include border-radius(6px);
                                @extend .ft-head;
                                padding: 4px 10px;
                                height: auto;
                                line-height: initial;
                                margin: 0 14px;
                                cursor: pointer;
                                &:hover {
                                    color: white;
                                }
                                &.disabled {
                                    background-color: map-get($cool-gray , 350) !important;
                                }
                            }
                        }
                    }
                }
            }
            .results-box {
                margin: 46px 0 30px 0;
                padding: 30px 0;
                .inner {
                    width: 60%;
                    margin: 0 auto;
                }
                .review-ans-btn {
                    margin: 30px 0 0 0;
                    background-color: map-get($cool-primary, 500);
                    color: white;
                    width: 220px;
                    font-size: 18px;
                }
            }
        }
    }
}

.review-pre-summary-wrapper {
    .summary-box {
        @include parent-breakpoint('lt-lg') {
            margin: 40px 0 0 0;
        }
    }
}

.assignment-assignment-confirm-wrapper {
    .check-img {
        width: 80px;
    }
}