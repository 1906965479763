@import "/src/assets/scss/abstract/abstract.scss";

$gray: map-get($parent-colors , 'cool-gray');
$cool-primary: map-get($parent-colors , 'cool-primary');
$cool-gray: map-get($parent-colors , 'cool-gray');

.signup-wrapper {
    margin: 0 !important;
    padding: 38px 24px !important;
    background: white;
    @include parent-breakpoint('gt-md') {
        padding: 38px 32px !important;
    }
    .content-container {
        margin: 0;
        .left {
            left: 0 !important;
        }
        .right {
           margin: 0 0 0 70px; 
           @include parent-breakpoint('lt-lg') {
                margin: 0;
                .form-container {
                    margin: 0;
                }
            }
        }
    }
    .register-container {
        .form-container {
            .form-box {
                margin: 20px 0 0 0;
            }
            .phone-box {
                .form-control {
                    width: 100%;
                    padding-left: 80px;
                }
                .react-tel-input .flag-dropdown {
                    background: none;
                    border: none;
                    .selected-flag {
                        padding: 0;
                        width: 70px;
                        display: flex;
                        justify-content: center;
                        background: transparent;
                        &:hover, &:focus {
                            background: transparent;
                        }
                        .flag {
                            .arrow {
                                margin-top: -5px;
                                left: 30px;
                                width: 8px;
                                height: 8px;
                                border: none;
                                border-top: 2px solid map-get($cool-gray , 900);
                                border-left: 2px solid map-get($cool-gray , 900);
                                transform: rotate(225deg);
                                &.up {
                                    margin-top: -2px;
                                    transform: rotate(45deg);
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                    .country-list {
                        box-shadow: 1px 3px 6px rgba(map-get($cool-gray, 960), 0.15);
                        border-radius: 8px;
                        margin: 2px 0 0 0 !important;
                        .country {
                            padding: 12px 14px;
                            font-size: 16px;
                            span {
                                @extend .ft-head;
                                letter-spacing: 0.4px;
                            }
                        }
                    }
                }
            }
        }
        .verify-card {
            margin: 40px 0 0 0;
           .card-body {
                padding: 40px;
                @include parent-breakpoint('xs') {
                    padding: 24px;
                }
                .otp-wrapper {
                    margin: 28px 0 38px 0;
                    .otp-box {
                        display: flex;
                        margin: auto;
                        input {
                            width: 78px !important;
                            height: 78px !important;
                            font-size: $font-h2;
                            background: map-get($cool-primary , 100);
                            border: none;
                            border-radius: 6px;
                            &:last-child {
                                margin-right: 0 !important;
                            }
                            @include parent-breakpoint('lg') {
                                width: 60px !important;
                                height: 60px !important;
                            }
                            @include parent-breakpoint('xs') {
                                width: 50px !important;
                                height: 50px !important;
                            }
                            &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                            }
                        }
                    }
                }
                .submit-box {
                    margin: 20px 0 0 0;
                }
           }
        }
    }
}