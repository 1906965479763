@import "/src/assets/scss/abstract/abstract.scss";

$gray: map-get($parent-colors , 'cool-gray');
$cool-primary: map-get($parent-colors , 'cool-primary');
$cool-green: map-get($parent-colors , 'cool-green');
$yellow: map-get($parent-colors , 'cool-yellow');

.select-plan-wrapper {
    position: relative;
    margin: 24px;
    padding: 24px 10px;
    @include parent-breakpoint('gt-md') {
        margin: 38px 32px;
        padding: 24px 20px;
    }
    .choose-term-wrapper {
        .btn-group {
            &.disabled {
                .btn {
                    pointer-events: none;
                }
            }
        }
    }
    .children-container {
        .children-box {
            width: 240px;
            overflow: hidden;
            .counter-btn {
                width: 64px;
                height: 48px;
                cursor: pointer;
                &.disabled {
                    pointer-events: none;
                    opacity: 0.4;
                }
            }
            .value-box {
                background-color: white;
                color: black;
            }
            &.disabled {
                .counter-btn {
                    pointer-events: none;
                }
            }
        }
    }
    .carousel-wrapper {
        .carousel-container {
            .plan-container {
                margin: 30px 0 0 0;
                .plan-box {
                    background-color: map-get($cool-primary , 100);
                    height: 100%;
                    width: calc(100% - 44px);
                    margin: 0 auto;
                    @include border-radius(15px);
                    h3, h4, h5, h6 {
                        @extend .ft-default;
                    }
                    @include parent-breakpoint('xl') {
                        margin: 0 30px;
                    }
                    @include parent-breakpoint('lt-lg') {
                        margin: 0 0 30px 0;
                        height: auto;
                    }
                    .plan-amount {
                        position: relative;
                        background-color: map-get($cool-primary , 900);
                        padding: 12px 0;
                        @include border-radius(15px);
                        .discount {
                            position: absolute;
                            @include circle(65px);
                            flex-direction: column;
                            background-color: map-get($cool-primary , 600);
                            box-shadow: 0 5px 10px rgba(#353535,0.19), 0 4px 4px rgba(#353535,0.23);
                            top: -30px;
                            right: -18px;
                            line-height: 20px;
                            @include parent-breakpoint('lt-lg') {
                                right: -10px;
                            }
                        }
                        .box {
                            background: white;
                            @include border-radius(2px);
                            color: map-get($cool-gray , 950);
                            position: absolute;
                            top: calc(50% - 14px);
                            padding: 6px 10px !important;
                            &.pro {
                                background-color: map-get($yellow , 500);
                            }
                        }
                        .pound-icon {
                            font-size: 30px;
                            margin-right: 3px;
                        }
                        .touch {
                            width: 80%;
                            margin: auto !important;
                            @include parent-breakpoint('lg') {
                                font-size: 26px;
                            }
                        }
                    }
                    .features {
                        .tick-icon {
                            color: map-get($cool-green , 500);
                            font-size: 24px;
                        }
                       p {
                            @extend .ft-default;
                        }
                    }
                    .signup-btn {
                        @include  border-radius(10px)
                    }
                    &.disabled {
                        background-color: map-get($cool-gray , 90);
                        .plan-amount {
                            background-color: map-get($cool-gray , 580);
                        }
                        .features {
                            .tick-icon {
                                color: map-get($cool-gray , 580);
                            }
                        }
                        .signup-btn {
                            background-color: map-get($cool-gray , 580);
                            pointer-events: none;
                        }
                    }
                }
            }
            .slider-wrapper {
                margin: 0 0 40px 0;
            }
            .control-dots {
                .dot {
                    width: 10px;
                    height: 10px;
                    background-color:map-get($cool-gray , 400);
                    box-shadow: none;
                    &.selected {
                        background-color: map-get($cool-primary , 500);
                    }
                }
            }
        }
    }
}

.select-plan {
    .modal {
        .modal-body {
            background-color: map-get($cool-primary , 100);
            .features-content {
                height: 100%;
                @include parent-breakpoint('lt-md') {
                    height: auto;
                    margin: 0 0 24px 0;
                }
                .type-btn {
                    @include border-radius(18px)
                }
                .list {
                    padding: 20px 8px !important;
                    .tick-icon {
                        color: map-get($cool-green , 500);
                        font-size: 24px;
                    }
                }
            }
        }
    }
}