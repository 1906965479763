@import "/src/assets/scss/abstract/abstract.scss";

.activity-wrapper {
    .tabs-box {
        @include parent-breakpoint('gt-sm') {
            margin-bottom: 16px;
        }
    }
    .tab-content {
        margin: 40px 0 0 0;
        //position: relative;
       //top: -88px;
        .list-group {
            border-color: map-get($cool-gray, 200);
            .list-group-item {
                @extend .ft-head;
                border-color: map-get($cool-gray, 200);
                padding: 12px 18px;
                cursor: pointer;
                color: map-get($cool-gray, 900);
                &:hover, &.active {
                    background-color: map-get($cool-primary, 300);
                    color: white;
                }
            }
        }
        .assigned-wrapper {
            display: flex;
            .assigned-box {
                background-color: white;
                padding: 12px 20px;
                @include border-radius(8px);
                .switch-container {
                    .form-check-input {
                        display: none;
                        &:checked {
                            & + .form-check-label {
                                .slider {
                                    background-color: map-get($cool-green, 400);
                                    &:before {
                                        background-color: white;
                                        left: 21px;
                                    }
                                }
                            }
                        }
                    }
                    .switch-box {
                        display: inline-block;
                        width: 38px;
                        height: 18px;
                        position: relative;
                        top: 5px;
                        .slider {
                            position: relative;
                            display: block;
                            border-radius: 30px;
                            background-color: map-get($cool-gray, 500);
                            cursor: pointer;
                            overflow: hidden;
                            transition: .4s;
                            width: 100%;
                            height: 100%;
                            &:before {
                              content: "";
                              position: absolute;
                              top: 1px;
                              left: 1px;
                              width: 16px;
                              height: 16px;
                              background-color: $white;
                              border-radius: 30px;
                              transition: .4s;
                            }
                        }
                    }
                }
            }
            @include parent-breakpoint('lt-md') {
                display: inline-block;
            }
        }
        .table-card {
            .table th {
                background-color: map-get($cool-gray, 200);
            }
            .table th, .table td {
                @extend .ft-default;
                color: map-get($cool-gray, 900);
                &.preview {
                    color: map-get($cool-primary, 950);
                    .disabled {
                        cursor: auto;
                        pointer-events: none;
                        color: map-get($cool-gray, 250);
                    }
                }
                .assign-btn {
                    display: inline-block;
                    background-color: map-get($cool-primary, 500);
                    color: white;
                    border-radius: 14px;
                    text-align: center;
                    padding: 6px 24px;
                    font-size: 16px;
                    letter-spacing: 0.6px;
                    cursor: pointer;
                    &.disabled {
                        cursor: auto;
                        pointer-events: none;
                        background-color: map-get($cool-gray, 250);
                        opacity: 0.6 !important;
                    }
                    &.darker {
                        background-color: map-get($cool-primary, 950);
                        cursor: auto;
                        pointer-events: none;
                    }
                }
            }
            .table tr {
                border-color: map-get($cool-gray, 200);
                td {
                    .input-group {
                        justify-content: center;
                    }
                    .react-datepicker-wrapper {
                        input {
                            width: 130px;
                            &.disabled {
                                pointer-events: none;
                                opacity: 0.6;
                            }
                        }
                    }
                }
            }
            @include parent-breakpoint('lt-md') {
                margin: 30px 0 0 0;
            }
        }
        .activity-assigned {
            .table td {
                color: map-get($cool-gray, 400);
            }
        }
    }
}